.react-tel-input .form-control {
  width: 100% !important;
  height: 54px !important;
}

.react-tel-input {
  margin-top: 16px;
  margin-bottom: 8px;
}

.react-tel-input .special-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 9pt;
  left: 10px;
}

.react-tel-input .form-control:focus {
  border-color: rgb(63, 81, 181);
  box-shadow: 0 0 0 1px rgb(63, 81, 181);
  border-radius: 3px;
  transition: none;
}

.terms {
  color: #3C9A68;
  font-weight: bold;
}

@media (max-width:600px) {

  .divider {
    display: none;
  }

  .MuiGrid-item.cadastro {
    margin-top: -90px;
  }
}